import { Route, Routes } from "react-router-dom";
import FourZeroFour from "./components/FourZeroFour";
import ScrollToTop from "./components/Helpers/ScrollToTop";
import StartJob from "./components/MyJobs/StartJob";
import Notification from "./components/Notification";
import AuthRoute from "./middleware/AuthRoute";
import AppDownloadPage from "./views/AppDownloadPage";
import AppleRedirectPage from "./views/AppleRedirectPage";
import AuthProfilePage from "./views/AuthProfilePage";
import AuthRedirect from "./views/AuthRedirect";
import BlogPage from "./views/BlogPage";
import CalendarPage from "./views/CalendarPage";
import CollectionItemPage from "./views/CollectionItemPage";
import FacebookRedirect from "./views/FacebookRedirect";
import FamilyAccPage from "./views/FamilyAccPage";
import FamilyManagePage from "./views/FamilyManagePage";
import FamilyMarketPage from "./views/FamilyMarketPage";
import FamilySettingsPage from "./views/FamilySettingsPage";
import ForgotPasswordPages from "./views/ForgotPasswordPages";
import ForgotPasswordPagesTwo from "./views/ForgotPasswordPagesTwo";
import HistoryPage from "./views/HistoryPage";
import HomePages from "./views/HomePages";
import JobGroupsPage from "./views/JobGroupsPage";
import LndPage from "./views/LndPage";
import LoginPage from "./views/LoginPage";
import LoginPageTwo from "./views/LoginPageTwo";
import ManageActiveJobs from "./views/ManageActiveJobs";
import ManageInterestOfferPage from "./views/ManageInterestOfferPage";
import MarketPlacePage from "./views/MarketPlacePage";
import MyActiveJobsPage from "./views/MyActiveJobsPage";
import MyCouponPage from "./views/MyCouponPage";
import MyJobsPage from "./views/MyJobsPage";
import MyOffersPage from "./views/MyOffersPage";
import MyPastDueJobsPage from "./views/MyPastDueJobsPage";
import MyReviewDueJobsPage from "./views/MyReviewDueJobsPage";
import MyTaskPage from "./views/MyTaskPage";
import MyWaitingJobsPage from "./views/MyWaitingJobsPage";
import MyWalletPage from "./views/MyWalletPage";
import OffersInterestPage from "./views/OffersInterestPage";
import ReferralPage from "./views/ReferralPage";
import RemindersPage from "./views/RemindersPage";
import ResourcePage from "./views/ResourcePage";
import SavedPage from "./views/SavedPage";
import SellPage from "./views/SellPage";
import SettingsPage from "./views/SettingsPage";
import SignupPage from "./views/SignupPage";
import SignupPageTwo from "./views/SignupPageTwo";
import TrackingPage from "./views/TrackingPage";
import UpdatePasswordPages from "./views/UpdatePasswordPages";
import UpdatePasswordPagesTwo from "./views/UpdatePasswordPagesTwo";
import UploadProductPage from "./views/UploadProductPage";
import UserProfilePage from "./views/UserProfilePage";
import VerifyLinkPages from "./views/VerifyLinkPages";
import VerifyLinkPagesTwo from "./views/VerifyLinkPagesTwo";
import VerifyPasswordPages from "./views/VerifyPasswordPages";
import VerifyPasswordPagesTwo from "./views/VerifyPasswordPagesTwo";
import VerifyYouPages from "./views/VerifyYouPages";
import VerifyYouPagesTwo from "./views/VerifyYouPagesTwo";
import YourPages from "./views/YourPage_";
import ParentWaitingPage from "./views/ParentWaitingPage";
import FamilyPendingOfferPage from "./views/FamilyPendingOfferPage";
import FamBlogPage from "./views/FamBlogPage"
import FamAIQuestionPage from "./views/FamAIQuestionPage"
import FamMyFilesPage from "./views/FamMyFilesPage"
import FamWorkInProgressPage from "./views/FamWorkInProgressPage";
import MyPastDueTasksPage from "./views/MyPastDueTasksPage";
import FamilyWalletPage from "./views/FamilyWalletPage";
import FamilyActivitiesPage from "./views/FamilyActivitiesPage";
import FamGamesPage from "./views/FamGamesPage";
import FamilyRoutesPage from "./views/FamilyRoutesPage";
import PromoPage from "./views/PromoPage";
import LearnMorePage from "./views/LearnMorePage";

export default function Routers() {
  return (
    <ScrollToTop>
      <Routes>
        {/* guest routes */}
        {process.env.REACT_APP_NEW_LOGIN_LAYOUT == 1 ? (
          <>
            <Route exact path="/login" element={<LoginPageTwo />} />
            <Route exact path="/signup" element={<SignupPageTwo />} />
            <Route
              exact
              path="/forgot-password"
              element={<ForgotPasswordPagesTwo />}
            />
            <Route
              exact
              path="/update-password"
              element={<UpdatePasswordPagesTwo />}
            />
            <Route path="/vemail" element={<VerifyLinkPagesTwo />} />
            <Route path="/complereset" element={<VerifyPasswordPagesTwo />} />
            <Route exact path="/outmessage" element={<VerifyYouPagesTwo />} />
            <Route exact path="/eoffer" element={<LoginPageTwo />} />
            <Route exact path="/invite" element={<LoginPageTwo />} />
            <Route exact path="/promo/:name/:id" element={<PromoPage />} />
          </>
        ) : (
          <>
            <Route exact path="/login" element={<LoginPage />} />
            <Route exact path="/signup" element={<SignupPage />} />
            <Route
              exact
              path="/forgot-password"
              element={<ForgotPasswordPages />}
            />
            <Route
              exact
              path="/update-password"
              element={<UpdatePasswordPages />}
            />
            <Route path="/vemail" element={<VerifyLinkPages />} />
            <Route path="/complereset" element={<VerifyPasswordPages />} />
            <Route exact path="/outmessage" element={<VerifyYouPages />} />
            <Route exact path="/eoffer" element={<LoginPage />} />
            <Route exact path="/invite" element={<LoginPage />} />
          </>
        )}
        <Route exact path="/login/auth" element={<AuthRedirect />} />
        <Route exact path="/login/auth/flogin" element={<FacebookRedirect />} />
        <Route exact path="/login/auth/apple" element={<AppleRedirectPage />} />
        <Route exact path="/lnd/*" element={<LndPage />} />
        <Route exact path="/app" element={<AppDownloadPage />} />

        {/* private route */}
        <Route element={<AuthRoute />}>
          <Route exact path="/" element={<HomePages />} />
          {/*  <Route exact path="/active-bids" element={<AcitveBidsPage />} />
          <Route exact path="/notification" element={<Notification />} />
          <Route exact path="/market-place" element={<MarketPlacePage />} />
          <Route exact path="/shop-details" element={<ShopDetailsPage />} />
        <Route exact path="/my-collection" element={<MyCollection />} />*/}
          <Route exact path="/reminders" element={<RemindersPage />} />
          <Route exact path="/tracking" element={<TrackingPage />} />
          <Route exact path="/calendar" element={<CalendarPage />} />
          <Route exact path="/resources" element={<ResourcePage />} />
          <Route exact path="/my-wallet/*" element={<MyWalletPage />} />
          <Route exact path="/family-wallet" element={<FamilyWalletPage />} />
          <Route exact path="/my-coupon" element={<MyCouponPage />} />
          <Route exact path="/notification" element={<Notification />} />
          <Route exact path="/market-place" element={<MarketPlacePage />} />
          <Route exact path="/market" element={<MarketPlacePage />} />
          <Route exact path="/familymarket" element={<FamilyMarketPage />} />
          <Route exact path="/suggested" element={<ParentWaitingPage />} />
          <Route exact path="/pending" element={<FamilyPendingOfferPage />} />
          <Route exact path="/fam-blog" element={<FamBlogPage />} />
          <Route exact path="/ai-question" element={<FamAIQuestionPage />} />
          <Route exact path="/myfiles" element={<FamMyFilesPage />} />
          <Route exact path="/ai-lab" element={<FamAIQuestionPage />} />
          <Route exact path="/fam-games" element={<FamGamesPage />} />
          <Route exact path="/work-in-progress" element={<FamWorkInProgressPage />} />

          <Route exact path="/pastdue" element={<MyPastDueTasksPage />} />
          <Route exact path="/notification" element={<Notification />} />
          <Route exact path="/mytask" element={<MyTaskPage />} />
          <Route exact path="/myjobs" element={<MyJobsPage />} />
          {/* <Route exact path="/add-job" element={<AddJobPage />} /> */}
          <Route exact path="/my-active-jobs" element={<MyActiveJobsPage />} />
          <Route
            exact
            path="/my-pastdue-jobs"
            element={<MyPastDueJobsPage />}
          />
          <Route exact path="/my-offers" element={<MyOffersPage />} />
          <Route exact path="/pend-interest" element={<MyWaitingJobsPage />} />
          <Route
            exact
            path="/my-review-jobs"
            element={<MyReviewDueJobsPage />}
          />

          {/* <Route exact path='/acc-family' element={<FamilyAccPage />} />
          <Route exact path="/acc-family/activities" element={<FamilyActivitiesPage />} />
          <Route exact path="/acc-family/familysettings" element={<FamilySettingsPage />} /> */}
          <Route path='/acc-family/*' element={<FamilyRoutesPage />} />

          <Route exact path="/manage-family" element={<FamilyManagePage />} />
          <Route exact path="/start-job" element={<StartJob />} />
          <Route exact path="/yourpage" element={<YourPages />} />
          <Route
            exact
            path="/manage-active-job"
            element={<ManageActiveJobs />}
          />
          <Route exact path="/blog-page" element={<BlogPage />} />
          <Route
            exact
            path="/offer-interest"
            element={<OffersInterestPage />}
          />
          <Route
            exact
            path="/manage-offer"
            element={<ManageInterestOfferPage />}
          />

          <Route
            exact
            path="/my-collection/collection-item"
            element={<CollectionItemPage />}
          />
          <Route exact path="/sell" element={<SellPage />} />
          <Route exact path="/saved" element={<SavedPage />} />
          <Route exact path="/history" element={<HistoryPage />} />
          <Route exact path="/learnmore" element={<LearnMorePage />} />
          {/*<Route exact path="/upload-product" element={<UploadProductPage />} />*/}
          <Route exact path="/my-uploads" element={<UploadProductPage />} />
          <Route exact path="/profile" element={<AuthProfilePage />} />
          <Route exact path="/user-profile" element={<UserProfilePage />} />
          <Route exact path="/settings" element={<SettingsPage />} />
          <Route exact path="/referral" element={<ReferralPage />} />
          <Route exact path="/job-groups" element={<JobGroupsPage />} />
        </Route>
        <Route path="*" element={<FourZeroFour />} />
      </Routes>
    </ScrollToTop>
  );
}
