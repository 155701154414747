import React, {useState, useEffect} from 'react'
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateUserDetails } from "../../../store/UserDetails";

import usersService from "../../../services/UsersService";

import PromoPageLayout from '../PromoPageLayout'
import InputCom from "../../Helpers/Inputs/InputCom";
import WrenchBoard from "../../../assets/images/wrenchboard-logo-text.png";
import LoadingSpinner from '../../../components/Spinners/LoadingSpinner'

import GoogleDownload from '../../../assets/images/download/andriod.jpg'
import IOSDownload from '../../../assets/images/download/apple.jpg'

export default function Promo() {

  const api = new usersService()

  const {name, id} = useParams() // PARAMETERS COMING FROM THE LINK
  // console.log(name, id)

  const navigate = useNavigate()

  const dispatch = useDispatch();

  const [requestStatus, setRequestStatus] = useState({loading:true, data:{}})

  const [completeSignUp, setCompleteSignUp] = useState({loading:false, status:false, message: ''});

  const [showPassword, setShowPassword] = useState(false);

  const [password, setPassword] = useState("");

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  // To Show and Hide Password
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleContinue = () => {
    let reqData = { // API REQUEST DATA/PAYLOAD
      username: requestStatus?.data?.email,
      promo: name,
      promo_owner: id,
      password: password,
      sessionid: '24271A99426'
      }
    setCompleteSignUp({loading:true, status:false, message: ''})
    if(!password){ // CHECKS FOR EMPTY PASSWORD
      setCompleteSignUp({loading:false, status:false, message: 'Please Enter Password'})
      return setTimeout(()=>{
        setCompleteSignUp({loading:false, status:false, message: ''})
      },2000)
    }
    api.loginPromo(reqData).then(res => { //loginPromo
      console.log('RES', res)
      if(res.data?.internal_return < 0 || !res?.data?.member_id || !res?.data?.uid || !res?.data?.session || res?.data?.status_message == 'VALID_LINK_NOT_FOUND'){
        setCompleteSignUp({loading:false, status:false, message: 'Unable to login'})
        return setTimeout(()=>{
          setCompleteSignUp({loading:false, status:false, message: ''})
        },4000)
      }

      // Do LOGIN HERE
      localStorage.setItem("member_id", `${res.data.member_id}`);
      localStorage.setItem("uid", `${res.data.uid}`);
      localStorage.setItem("session_token", `${res.data.session}`);
      localStorage.setItem("wallet_available_status", `${res.data.wallet_available_status}`);
      if (res.data?.account_type == "FAMILY") {
        sessionStorage.setItem("family_uid", res.data?.family_uid);
        sessionStorage.setItem("parent_uid", res.data?.parent_uid);
      }
      dispatch(updateUserDetails({ ...res.data }));
      setTimeout(() => {
        navigate("/", { replace: true });
        setCompleteSignUp({loading:false, status:true, message: ''})
      }, 2000);

    }).catch(err => {
      setCompleteSignUp({loading:false, status:false, message: 'Opps! try again'})
      setTimeout(()=>{
        setCompleteSignUp({loading:false, status:false, message: ''})
      },4000)
    })
  }

  useEffect(()=>{
    let reqData = { // API REQUEST DATA/PAYLOAD
      promo: name,
      promo_owner: id,
      sessionid: '79970A12501'
      }
    api.verifyPromo(reqData).then(res => {
      if(res?.data?.internal_return < 0 || !res?.data?.email || res?.data?.status_message != 'VALID_LINK_FOUND'){
        return setRequestStatus({loading:false, data:{}})
      }
      setRequestStatus({loading:false, data:res?.data})
    }).catch(err => {
      setRequestStatus({loading:false, data:{}})
    })
  },[])

  return (
    <PromoPageLayout>
      <div className="w-full">
        <div className="mb-5">
          <Link to="#">
            <img
              src={WrenchBoard}
              alt="wrenchboard"
              className="h-10 mx-auto"
            />
          </Link>
        </div>
        {requestStatus.loading ?
          <div className='flex flex-col justify-center items-center'>
            <LoadingSpinner height='h-40' size='8' />
            <p>Loading...</p>
            <p>please do not refresh</p>
          </div>
          : Object.keys(requestStatus.data).length > 0 ?
          <div className="flex place-content-center">
              <div className="w-10/12 pb-3">
                <div className="p-6 input-area login-area border-2 border-[#4687ba] rounded-2xl">
                  <div className="input-item mb-5">
                    <InputCom
                      labelClass="tracking-wider"
                      fieldClass="sm:px-6 px-2"
                      value={requestStatus?.data?.email}
                      // inputHandler={handleEmail}
                      placeholder="Your Email"
                      label="Email"
                      name="email"
                      type="email"
                      iconName="message"
                      disable={true}
                    />
                  </div>

                  <div className="input-item mb-5">
                    <InputCom
                      labelClass="tracking-wider"
                      fieldClass="sm:px-6 px-2 tracking-[0.25em] text-2xl"
                      value={password}
                      inputHandler={handlePassword}
                      placeholder="● ● ● ● ● ●"
                      label="Set Password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      onClick={togglePasswordVisibility}
                      passIcon={showPassword ? "password" : "password"}
                    />
                  </div>

                  {completeSignUp.message && (
                    <div className="relative p-4 text-[#912741] bg-[#fcd9e2] border-[#fbc6d3] mb-4 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]">
                      {completeSignUp.message}
                    </div>
                  )}

                  <div className="flex justify-center">
                    <button
                      name="full"
                      onClick={handleContinue}
                      type="button"
                      disabled={completeSignUp.loading}
                      className={`btn-login  rounded-full text-xl text-white flex justify-center bg-[#4687ba] hover:bg-[#009ef7] transition-all duration-300 items-center text-[15px]`}
                    >
                      {completeSignUp.loading ? (
                        <div className="signup btn-loader"></div>
                      ) : (
                        <>Continue</>
                      )}
                    </button>
                  </div>
                </div>

              {/* APP DOWNLOAD STORE */}
              <div className="w-full mt-4">
                <div className="w-full flex justify-center items-center gap-4">
                  <div className="w-32 lg:w-48">
                    <a
                      // className="px-1 py-1 lg:py-2 flex justify-center items-center gap-1 w-full rounded-md bg-black text-white hover:text-slate-500 hover:shadow-lg transition-all duration-300"
                      target="_blank"
                      rel="noreferrer"
                      href={process.env.REACT_APP_APPLE_APP}
                    >
                      {/* <i className="fa-brands fa-apple text-3xl"></i>
                      <div className="flex flex-col">
                        <span className="text-[11px]">Available on the</span>
                        <span className="text-[12px] lg:text-base">
                          App Store
                        </span>
                      </div> */}
                      <img src={IOSDownload} className='w-full h-auto' alt='IOS Download' />
                    </a>
                  </div>
                  <div className="w-32 lg:w-48">
                    <a
                      // className="px-1 py-1 lg:py-2 flex justify-center items-center gap-1 w-full rounded-md bg-black text-white hover:text-slate-500 hover:shadow-lg transition-all duration-300"
                      target="_blank"
                      rel="noreferrer"
                      href={process.env.REACT_APP_ANDROID_APP}
                    >
                      {/* <i className="fa-brands fa-google-play text-2xl"></i>
                      <div className="flex flex-col">
                        <span className="text-[11px]">Available on the</span>
                        <span className="text-[12px] lg:text-base">
                          Google Play
                        </span>
                      </div> */}
                      <img src={GoogleDownload} className='w-full h-auto' alt='IOS Download' />
                    </a>
                  </div>
                </div>
              </div>
              </div>
          </div>
          :
          <ErrorComponent onClick={() => navigate("/login")} />
        }
      </div>
    </PromoPageLayout>
  )
}


const ErrorComponent = ({ onClick }) => (
  <div className="input-area">
    <div className="my-5">
      <p className="text-[14px] leading-[19px] text-center text-[#181c32]">
        This error occurs because you have already verified this link or the
        link has expired. Try login or reset password. If none worked, try to
        create the account from the start.
      </p>
    </div>

    <div className="signin-area flex justify-center mb-3.5">
      <button
        onClick={onClick}
        type="button"
        className={`rounded-[0.475rem] mb-6 text-[15px] font-semibold text-[#009ef7] hover:text-white flex justify-center bg-[#f1faff] hover:bg-[#009ef7] transition-all duration-300 items-center py-[0.8875rem] px-[1.81rem]`}
      >
        <span>Return Home</span>
      </button>
    </div>
  </div>
);
