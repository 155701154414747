import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import WrenchBoard from "../../../assets/images/wrenchboard-logo-text.png";
import usersService from "../../../services/UsersService";
import InputCom from "../../Helpers/Inputs/InputCom";
import AuthLayout from "../AuthLayout2";
import EmailValidator from "../../../lib/EmailValidator";
import ForgetPwdResponse from "../ForgetPwdResponse";

import ReCAPTCHA from "react-google-recaptcha";

export default function ForgotPassword() {
  const [checked, setValue] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  // email
  const [email, setMail] = useState("");
  const [msgError, setMsgError] = useState("");
  const [msgSuccess, setMsgSuccess] = useState(null);

  const navigate = useNavigate();
  const userApi = new usersService();

  const handleEmail = (e) => {
    setMail(e?.target.value);
  };

  // const humanChecker = () => {
  //   setValue(!checked);
  // };

  function humanChecker(value) {
    // console.log("Captcha value:", value);
    if(value){
      setValue(true)
    }else{
      setValue(false)
    }
  }

  const resetHandler = async () => {
    if (email == "") {
      setMsgError("An email is required");
      return setTimeout(() => {
        setMsgError(null);
      }, process.env.REACT_APP_RESET_START_ERROR_TIMEOUT);
    } 
    if (!checked) {
      setMsgError("Check if you are human");
      return setTimeout(() => {
        setMsgError(null);
      }, process.env.REACT_APP_RESET_START_ERROR_TIMEOUT);
    }

    if(!EmailValidator(email)){ // CHECKS IF EMAIL IS VALID
      setMsgError("Invalid Email");
      return setTimeout(() => {
        setMsgError(null);
      }, process.env.REACT_APP_RESET_START_ERROR_TIMEOUT);
    }

    if (email !== "" && checked) {
      const reqData = { email };
      setResetLoading(true);
      try {
        const res = await userApi.StartResetPassword(reqData);
        if (res.status === 200 && res?.data?.internal_return >= 0) {
          setMsgSuccess(true);
          setMail("");
          setValue(false);
          setResetLoading(false);
        }else{
          setMsgSuccess(false);
        }
      } catch (error) {
        setMsgSuccess(false);
        setResetLoading(false);
        setMail("");
        setMsgError("An error occurred");
        throw new Error(error);
      } finally {
        setTimeout(() => {
          setMsgError(null);
        }, process.env.REACT_APP_RESET_START_ERROR_TIMEOUT);
      }
    }
  };

  return (
    <>
      <AuthLayout slogan="Welcome to WrenchBoard">
        <div className="w-full">
          <div className="mb-12">
            <Link to="#">
              <img
                src={WrenchBoard}
                alt="wrenchboard"
                className="h-10 mx-auto"
              />
            </Link>
          </div>
          <div className="flex place-content-center">
            <div className="w-10/12">
            {msgSuccess == null ?
              <>
                <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                  <h1 className="text-[#181c32] font-semibold dark:text-white mb-3 leading-[27.3px] text-[22.75px]">
                    Forget Password
                  </h1>
                  <span className="text-gray-400 font-medium text-[16.25px] leading-[24.375px]">
                    Enter your email to reset your password.
                  </span>
                </div>
                <div className="input-area">
                  <div className="input-item mb-10">
                    <InputCom
                      fieldClass="px-6"
                      placeholder="Your Username/Email"
                      label="Email"
                      name="email"
                      type="email"
                      value={email}
                      inputHandler={handleEmail}
                      iconName="message"
                    />
                  </div>
                  {/* hCaptha clone for the time being */}
                  <div className="mb-10 flex justify-center w-full">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY}
                      onChange={humanChecker}
                    />
                  </div>
                  {/* <div className="mb-10">
                    <div className="w-[303px] h-[78px] mx-auto overflow-hidden">
                      <div className="w-[300px] h-[74px] bg-white bottom-[1px] rounded border-gray-100 overflow-hidden cursor-pointer">
                        
                        <div className="h-full relative inline-block">
                          <div className="relative table top-0 h-full">
                            <div className="table-cell align-middle">
                              <div className="relative w-[30px] h-[30px] mx-[15px]">
                                <input
                                  type="checkbox"
                                  name="human-checkbox"
                                  id="human-checkbox"
                                  className="w-[28px] h-[28px] border-[1px] rounded border-gray-400 checked:bg-white"
                                  checked={checked}
                                  onChange={humanChecker}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="h-full relative inline-block w-[170px]">
                          <label className="relative table top-0 h-full">
                            <label className="table-cell align-middle">
                              <label
                                className="text-800 text-sm"
                                htmlFor="human-checkbox"
                              >
                                I am human
                              </label>
                            </label>
                          </label>
                        </div>
                        <div className="h-full relative inline-block w-16"></div>
                      </div>
                    </div>
                  </div> */}
                  {msgError && (
                    <div className="relative p-4 text-[#912741] bg-[#fcd9e2] border-[#fbc6d3] mb-4 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]">
                      {msgError}
                    </div>
                  )}
                  <div className="signin-area mb-3.5">
                    <div className="flex justify-center items-center gap-4">
                    <button
                        type="button"
                        onClick={() => navigate("/login")}
                        className={`h-[48px] rounded-full mb-6 text-[15px] font-semibold text-white hover:text-white flex justify-center bg-red-500 hover:bg-red-600 transition-all duration-300 items-center py-[0.8875rem] px-[1.8125rem] `}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={resetHandler}
                        className={`h-[48px] rounded-full mb-6 text-[15px] font-semibold text-white flex justify-center bg-[#4687ba] hover:bg-[#009ef7] transition-all duration-300 items-center py-[0.8875rem] px-[1.81rem]`}
                      >
                        {resetLoading ? (
                          <div className="signup btn-loader"></div>
                        ) : (
                          <span>Send Code</span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </>
            :
            <ForgetPwdResponse 
              title={'Forget Password'}
              message={msgSuccess? `Check your email for the link to continue password reset. Note the reset link will expire short time` : 'We are unable to continue with your request. Please try another username or contact us for help'}
              type={msgSuccess}
            />
            }
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
