import Axios from "axios";
import { apiConst } from "../lib/apiConst";

class usersService {
  constructor() {
    console.log("WRB Service Entry");
  }

  MyPageIntro(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      ...reqData,
    };
    return this.postAuxEnd("/mypageintro", postData);
  }

  MyPageLoad() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_ACCOUNT_MYPAGE,
    };
    return this.postAuxEnd("/mypageload", postData);
  }

  CreateUser(reqData) {
    localStorage.setItem("session_token", ``);
    return this.postAuxEnd("/createuser", reqData);
  }

  blogData() {
    return this.getAuxEnd("/blogdata", {});
  }

  CompleteOauthLogin(reqData) {
    localStorage.setItem("session_token", ``);
    return this.postAuxEnd("/authlogin", reqData);
  }
  CompleteSignUp(postData) {
    localStorage.setItem("session_token", ``);

    var reqData = {
      login_mode: 100,
      sessionid: "STARTER-NOTREAL",
      action: apiConst.WRENCHBOARD_COMPLETE_MOBILEUSER,
      ...postData,
    };
    return this.postAuxEnd("/completesignuplink", reqData);
  }

  assignJobTask(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_JOB_OFFER_SYSTEM,
      ...reqData,
    };
    return this.postAuxEnd("/assigntask", postData);
  }

  // FUNCTION TO GET USER CURRENT TASK DUE TIME
  getHomeDate() {
    var postData = {
      // uuid: localStorage.getItem("uid"),
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
    };
    return this.postAuxEnd("/dashdata", postData);
  }

  getRecentActivities() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_ACCOUNT_RECENTS,
    };
    return this.postAuxEnd("/recentactivities", postData);
  }

  logInUser(postData) {
    localStorage.setItem("session_token", ``);

    var reqData = {
      action: apiConst.WRENCHBOARD_ACCOUNT_LOGIN,
      ...postData,
    };
    return this.postAuxEnd("/userlogin", reqData);
  }

  authStart(reqData) {
    localStorage.setItem("session_token", ``);
    return this.postAuxEnd("/authstart", reqData);
  }

  authLogin(reqData) {
    localStorage.setItem("session_token", ``);
    return this.postAuxEnd("/authlogin", reqData);
  }

  marketJobData(reqData) {
    var postData = {
      uuid: localStorage.getItem("uuid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
    };
    return this.postAuxEnd("/getjobsdata", postData);
  }
  //
  getUserBankList(reqData) {
    var postData = {
      uuid: localStorage.getItem("uuid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
    };
    return this.postAuxEnd("/mybanklist", postData);
  }
  getUserWallets() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
    };
    return this.postAuxEnd("/getwallets", postData);
  }
  getApiGate() {
    // localStorage.setItem("session_token", ``);
    return this.postAuxEnd("/apigate", {});
  }

  getLoadProfile() {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 0,
      limit: 100,
    };
    return this.postAuxEnd("/loadprofile", {});
  }

  getUploadedList() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 1,
      limit: 20,
      action: apiConst.WRENCHBOARD_MYFILES_LIST,
    };
    return this.postAuxEnd("/myfiles", postData);
  }

  getOffersList() {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 0,
      limit: 100,
    };
    return this.postAuxEnd("/offerslist", postData);
  }

  getPendingJob() {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 0,
      limit: 100,
    };
    return this.postAuxEnd("/pendingjob", postData);
  }

  /*
    getActiveJobList -  All available Jobs
     */
  getActiveJobList() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 0,
      limit: 100,
    };
    return this.postAuxEnd("/getjobsdata", postData);
  }

  /*
    getMyActiveJobList  - List of jobs active under this user
     */
  // getMyActiveJobList(){
  //     var postData = {
  //         uuid: localStorage.getItem("uid"),
  //         member_id: localStorage.getItem("member_id"),
  //         sessionid: localStorage.getItem("session_token"),
  //         page:0,
  //         offset:0,
  //         limit :100
  //     };
  //     return this.postAuxEnd("/getjobsdata", postData);
  // }
  getHeroJBanners() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 0,
      limit: 100,
    };
    return this.postAuxEnd("/homebanners", postData);
  }

  getRecentActivitiedData() {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 0,
      limit: 100,
    };
    return this.postAuxEnd("/dashrecent", postData);
  }
  getGetPendingJobs() {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 0,
      limit: 100,
    };
    return this.postAuxEnd("/getpendingjobs", postData);
  }

  getUsersCards() {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 0,
      limit: 100,
    };
    return this.postAuxEnd("/userscards", postData);
  }

  getCouponPending() {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 0,
      limit: 100,
    };
    return this.postAuxEnd("/couponpending", postData);
  }

  // API FUNCTION TO GET COUPON HISTORY
  getRecipient() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 1,
      limit: 20,
      action: apiConst.WRENCHBOARD_ACCOUNT_USER_RECIPIENT,
    };
    return this.postAuxEnd("/recipients", postData);
  }

  //END POINT CALL FOR UPDATE PROFILE
  addRecipient(data) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      ...data,
    };
    return this.postAuxEnd("/addrecipient", postData);
  }

  // API FUNCTION TO GET SEND MONEY FEE
  getSendMoneyFee(amount) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      amount,
      action: apiConst.WRENCHBOARD_SMONEY_PROCFEE,
    };
    return this.postAuxEnd("/sendmoneyfee", postData);
  }

  // Start Credit for Cards
  getStartCredit(value) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_USER_STARTCREDIT,
      ...value,
    };
    return this.postAuxEnd("/startcredit", postData);
  }

  // Paying using Previous Cards
  getPaidPrevCard(value) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_USER_USESAVEDCC,
      ...value,
    };
    return this.postAuxEnd("/payprevcard", postData);
  }

  // Paying using New Card
  getPaidNewCard(value) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_USER_ADNEWCC,
      ...value,
    };
    return this.postAuxEnd("/paynewcard", postData);
  }

  getFamilySampleTasks() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      limit: 30,
      offset: 0,
      action: apiConst.WRENCHBOARD_FAMILY_SAMPLETASKS,
    };
    return this.postAuxEnd("/familysampletasks", postData);
  }

  sendFamilySuggestedTasks(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_JOB_CREATEJOB,
      ...reqData,
    };
    return this.postAuxEnd("/familysuggesttasks", postData);
  }

  // Family Manage
  ManageFamily(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_FAMILY_MANAGE,
      ...reqData,
    };
    return this.postAuxEnd("/familymanage", postData);
  }

  // Family Tasks
  ManageTasks(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      job_mode: "ACTIVE",
      limit: 30,
      offset: 0,
      action: apiConst.WRENCHBOARD_JOB_ACTIVE,
      ...reqData,
    };
    return this.postAuxEnd("/jobmanageractive", postData);
  }

  // Family Waitlist
  ManageFamilyWaitlist() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_JOB_CREATEJOB || apiConst.WRENCHBOARD_FAMILY_LIST,
      limit: 30,
      offset: 0,
    };
    return this.postAuxEnd("/familywaitingtasks", postData);
  }

  ManageFamilyNewWaitlist() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_FAMILY_LIST,
      limit: 20,
      page: 1,
      offset: 0,
    };
    return this.postAuxEnd("/familywaitingtasks", postData);
  }

  ManageFamilyPending() {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 0,
      offset: 0,
      limit: 100,
    };
    return this.postAuxEnd("/jobmanageroffers", postData);
  }

  // Task for the person doing the job
  getMyActiveTaskList() {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 0,
      offset: 0,
      limit: 100,
      allstatus: 0,
    };
    return this.postAuxEnd("/activetaskslist", postData);
  }
  getMyActiveJobList() {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      job_mode: "ACTIVE",
      page: 0,
      offset: 0,
      limit: 100,
    };
    return this.postAuxEnd("/jobmanageractive", postData);
  }

  getMyPastDueJobList() {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      job_mode: "PASTDUE",
      page: 0,
      offset: 0,
      limit: 100,
    };
    return this.postAuxEnd("/jobmanageractive", postData);
  }
  getMyReviewDueJobList() {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      job_mode: "REVIEW",
      page: 0,
      offset: 0,
      limit: 100,
    };
    return this.postAuxEnd("/jobmanageractive", postData);
  }

  getMyPendingJobList() {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 0,
      offset: 0,
      limit: 100,
    };
    return this.postAuxEnd("/jobmanageroffers", postData);
  }
  //
  getResourceList() {
    // jobs you have shown inteterest in
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 0,
      offset: 0,
      limit: 100,
    };
    return this.postAuxEnd("/resources", postData);
  }

  // ASK QUESTION RESOURCES
  askResourcesResult(reqData) {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_FAMILY_LIST,
      ...reqData,
    };
    return this.postAuxEnd("/askresources", postData);
  }

  getMyWiatingJobList() {
    // jobs you have shown inteterest in
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 0,
      offset: 0,
      limit: 100,
    };
    return this.postAuxEnd("/waitinginterest", postData);
  }
  getMyJobList(reqData = {}) {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 0,
      offset: 0,
      limit: 100,
      ...reqData,
    };
    return this.postAuxEnd("/jobmanagerlist", postData);
  }
  // API FUNCTION TO GET COUPON HISTORY
  getCouponHx() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 1,
      limit: 20,
      action: apiConst.WRENCHBOARD_COUPON_MEMLIST,
    };
    return this.postAuxEnd("/couponhx", postData);
  }

  getPurchaseHx() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 1,
      limit: 20,
      action: apiConst.WRENCHBOARD_MOBILE_PURCHASEHX,
    };
    return this.postAuxEnd("/purchasehx", postData);
  }

  // API FUNCTION TO GET FAMILY REWARD HISTORY
  getFamilyRewardHx() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      offset: 1,
      limit: 20,
      action: apiConst.WRENCHBOARD_FAMILY_TRANSFERHX,
    };
    return this.postAuxEnd("/familyrewardhx", postData);
  }

  // API FUNCTION TO GET CONTRACT HISTORY
  getContractHx() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      offset: 1,
      limit: 20,
      // action: apiConst.WRENCHBOARD_FAMILY_TRANSFERHX,
      action: "",
    };
    return this.postAuxEnd("/contracthx", postData);
  }

  // API FUNCTION TO GET PAYMENT HISTORY
  getPaymentHx() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 1,
      limit: 20,
      action: apiConst.WRENCHBOARD_MOBILE_PAYMENTHX,
    };
    return this.postAuxEnd("/paymenthx", postData);
  }

  // API FUNCTION TO GET PAYMENT HISTORY
  sendMoney(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_SMONEY_MEMBER,
      ...reqData,
    };
    return this.postAuxEnd("/sendmoney", postData);
  }

  //END POINT CALL FOR REFERRAL HISTORY
  getReferralHx() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      offset: 1,
      limit: 100,
      action: apiConst.WRENCHBOARD_ACCOUNT_REFFERHX,
    };
    return this.postAuxEnd("/refferhx", postData);
  }

  //END POINT CALL FOR UPDATE PROFILE
  updateProfile(post) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_MOBILE_UPDATEPROFILE,
      ...post,
    };
    return this.postAuxEnd("/updateprofile", postData);
  }

  //END POINT CALL FOR GETTING USER PROFILE
  loadProfile(post) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
    };
    return this.postAuxEnd("/loadprofile", postData);
  }

  getFamilyList() {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 0,
      limit: 100,
    };
    return this.postAuxEnd("/familylist", postData);
  }

  getRelativeSettings(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_RELATIVE_SETTINGS,
      ...reqData
    };
    return this.postAuxEnd("/relativesettings", postData);
  }

  sendRelativeReminder(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_RELATIVE_REMINDER,
      ...reqData
    };
    return this.postAuxEnd("/relativereminder", postData);
  }

  getRelativeEditKids(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_RELATIVE_EDIT_KIDS,
      ...reqData
    };
    return this.postAuxEnd("/relativeeditkids", postData);
  }

  getFamilyAdd() {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 0,
      limit: 100,
    };
    return this.postAuxEnd("/familyadd", postData);
  }

  getFamilyUpdate(reqdata) {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 0,
      limit: 100,
      action: apiConst.WRENCHBOARD_FAMILY_UPDATE,
      ...reqdata,
    };
    return this.postAuxEnd("/familyupdate", postData);
  }

  getFamilyManage() {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 0,
      limit: 100,
    };
    return this.postAuxEnd("/familymanage", postData);
  }

  // END POINT CALL FOR ACCOUNT TOP
  startTopUp(post) {
    alert("WHY ARE WE CALLING THIS ?");
    // var postData = {
    //   uid: localStorage.getItem("uid"),
    //   member_id: localStorage.getItem("member_id"),
    //   sessionid: localStorage.getItem("session_token"),
    //   action: 11062,
    //   ...post,
    // };
    // console.log("starttopup",postData);
    // return this.postAuxEnd("/starttopup", postData);
  }

  resultTopUp(post) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: Number(localStorage.getItem("member_id")),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_ACCOUNT_TOPUP_RESULT,
      ...post,
    };
    // console.log("topupresult", postData);
    return this.postAuxEnd("/topupresult", postData);
  }

  //END POINT CALL FOR SENDING REFERRAL MESSAGE
  sendReferralMsg(postData) {
    var reqData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_ACCOUNT_SENDREFER,
      ...postData,
    };
    return this.postAuxEnd("/sendreferral", reqData);
  }

  StartResetPassword(postData) {
    var reqData = {
      action: apiConst.WRENCHBOARD_RESET_PASSWORD,
      ...postData,
    };
    return this.postAuxEnd("/startresetpasword", reqData);
  }

  CompleteResetPassword(postData) {
    var reqData = {
      sessionid: "DUMMY-CANNOT_BE_EMPTY",
      action: apiConst.WRENCHBOARD_COMPLETE_PASSWORDRESET,
      ...postData,
    };
    return this.postAuxEnd("/stepresetpass", reqData);
  }

  getCouponRedeem(reqData) {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_COUPON_REDEEM,
      ...reqData,
    };
    return this.postAuxEnd("/couponredeem", postData);
  }

  // Country Data {GET}
  getSignupCountryData() {
    return this.postAuxEnd("/signupcountry", {});
  }

  // END POINT TO GET BANK NAME
  getCountryBank(value) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_ACCOUNT_COUNTRY_BANKS,
      ...value,
    };
    return this.postAuxEnd("/countrybanks", postData);
  }

  // END POINT TO GET ACCOUNT TYPE
  getAccountTypes() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_ACCOUNT_ACCOUNT_TYPES,
    };
    return this.postAuxEnd("/accounttypes", postData);
  }

  // Manage Interest
  MarketInterest(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      msg_type: "JOB",
      action: apiConst.WRENCHBOARD_JOB_SEND_INTEREST,
      ...reqData,
    };
    return this.postAuxEnd("/marketinterest", postData);
  }

  MarketMessage(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      msg_type: "JOB",
      action: apiConst.WRENCHBOARD_JOB_SEND_QUESTION,
      ...reqData,
    };
    return this.postAuxEnd("/marketmessage", postData);
  }

  // END POINT TO ACCEPT TERMS AND AGREEMENT
  jobManagerAgree() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_JOB_POSTAGREE,
    };
    return this.postAuxEnd("/jobmanageragree", postData);
  }

  // END POINT TO TO CREATE A JOB
  jobManagerCreateJob(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_JOB_CREATEJOB,
      ...reqData,
    };
    return this.postAuxEnd("/jobmanagercreatejob", postData);
  }

  jobManagerUpdateJob(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_JOB_CREATEJOB,
      ...reqData,
    };
    return this.postAuxEnd("/jobmanagerupdatejob", postData);
  }

  pendingJobExtend(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      extend_days: 7,
      action: apiConst.WRENCHBOARD_JOB_EXTEND_EXPIRE,
      ...reqData,
    };
    return this.postAuxEnd("/pendingjobextend", postData);
  }

  pendingJobSendTome(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      mode: 200,
      action: apiConst.WRENCHBOARD_JOB_RESEND_MESSAGE,
      ...reqData,
    };
    return this.postAuxEnd("/pendingjobsendtome", postData);
  }

  pendingCancelOffer(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_JOB_CANCEL_OFFER,
      ...reqData,
    };
    return this.postAuxEnd("/pendingjobcancel", postData);
  }

  // FUNCTION TO GET ACTIVE JOB MESSAGE LIST
  activeJobMesList(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      limit: 30,
      action: apiConst.WRENCHBOARD_CONTRACT_MSGLIST,
      offset: 0,
      ...reqData,
    };
    return this.postAuxEnd("/activejobmsglist", postData);
  }

  // FUNCTION TO SEND ACTIVE JOB TASK MESSAGE
  sendTaskMessage(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_CONTRACT_MESSAGE,
      ...reqData,
    };
    return this.postAuxEnd("/sendtaskmessage", postData);
  }

  // FUNCTION TO SEND FILE UPLOAD
  sendFiles(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_CONTRACT_MESSAGE,
      ...reqData,
    };

    const formData = new FormData();
    for (let data in postData) {
      formData.append(data, postData[data]);
    }

    return this.postAuxEnd("/uploads", postData);
  }

   // FUNCTION FOR NEW FILE UPLOAD
   sendFilesNew(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_CONTRACT_MESSAGE,
      ...reqData,
    };

    const formData = new FormData();
    for (let data in postData) {
      formData.append(data, postData[data]);
    }

    return this.postAuxEnd("/upload/contract", formData, true);
  }

    // FUNCTION TO UPLOAD RESOURCE FILES
    uploadFile(reqData) {
      var postData = {
        uid: localStorage.getItem("uid"),
        member_id: localStorage.getItem("member_id"),
        sessionid: localStorage.getItem("session_token"),
        action: apiConst.WRENCHBOARD_CONTRACT_MESSAGE,
        ...reqData,
      };
  
      const formData = new FormData();
      for (let data in postData) {
        formData.append(data, postData[data]);
      }
  
      return this.postAuxEnd("/upload/resources", formData, true);
    }

    // FUNCTION TO UPLOAD TASK FILES
    uploadTaskFile(reqData) {
      var postData = {
        uid: localStorage.getItem("uid"),
        member_id: localStorage.getItem("member_id"),
        sessionid: localStorage.getItem("session_token"),
        // action: apiConst.WRENCHBOARD_CONTRACT_MESSAGE,
        ...reqData,
      };
  
      const formData = new FormData();
      for (let data in postData) {
        formData.append(data, postData[data]);
      }
  
      return this.postAuxEnd("/upload/task", formData, true);
    }

  // END POINT TO DELETE  A JOB
  deleteJob(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_JOB_DELETEJOB,
      ...reqData,
    };
    return this.postAuxEnd("/jobmanagerdeletejob", postData);
  }

  verifyEmail(code) {
    const reqData = {
      verify_link: code,
      action: apiConst.WRENCHBOARD_VERIFY_PENDING_LINK,
    };
    return this.postAuxEnd("/verifysignuplink", reqData);
  }

  // END POINT FOR OFFER RESPONSE (i.e TO ACCEPT, REJECT, CANCEL, ETC OFFER)
  offersResponse(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_MOBILE_OFFERSRESP,
      ...reqData,
    };
    return this.postAuxEnd("/offersresponse", postData);
  }

  // END POINT FOR OFFERS INTEREST LIST
  offersInterestList() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_JOB_OFFER_INTLIST,
      limit: 30,
      offset: 0,
    };
    return this.postAuxEnd("/offersinterestlist", postData);
  }

  // END POINT FOR PROCESSING OFFER INTEREST
  offersInterestProc(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_JOB_OFFER_INTLIST,
      ...reqData,
    };
    return this.postAuxEnd("/offersinterestproc", postData);
  }

  // END POINT FOR WORKER TO MARK TASK AS COMPLETED
  workerJobAction(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_CONTRACT_STATUS,
      ...reqData,
    };
    return this.postAuxEnd("/activetaskstatus", postData);
  }

  // END POINT FOR OWNER JOB ACTION
  ownerJobAction(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_CONTRACT_STATUS,
      ...reqData,
    };
    return this.postAuxEnd("/activejobstatus", postData);
  }

  // END POINT FOR OWNER JOB ACTION
  getFaq() {
    var postData = {
      sessionid: localStorage.getItem("session_token"),
    };
    return this.postAuxEnd("/faq", postData);
  }

  getMyNotifications() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      limit: 30,
      offset: 0,
      action: apiConst.WRENCHBOARD_ACCOUNT_NOTIFICATIONS,
    };
    return this.postAuxEnd("/mynotifications", postData);
  }

  // END POINT TO GET LIST OF USER PREVIOUS CARDS
  payListCard() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_USER_CARDLIST,
    };
    return this.postAuxEnd("/paylistcard", postData);
  }

  // END POINT TO SEND OFFER INTEREST MESSAGE
  offerInterestMsg(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_JOB_MRKTINT_QUEST,
      ...reqData,
    };
    return this.postAuxEnd("/offerinterestmsg", postData);
  }

  // END POINT TO GET OFFER INTEREST MESSAGE
  offerInterestListMsg(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_JOB_MRKTINT_QUEST,
      ...reqData,
    };
    return this.postAuxEnd("/offerinterestlistmsg", postData);
  }

  // END POINT TO GET INTEREST STATS
  interestStatistics(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_INTEREST_STATS,
      ...reqData,
    };
    return this.postAuxEnd("/intereststats", postData);
  }

  // TO ADD FAMILY
  addFamily(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      ...reqData,
    };
    return this.postAuxEnd("/familyadd", postData);
  }

  familyListings(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      limit: 20,
      offset: 0,
      action: apiConst.WRENCHBOARD_FAMILY_LIST,
    };
    return this.postAuxEnd("/familylist", postData);
  }

  // FUNCTION TO ASSIGN TASK TO FAMILY MEMBER
  assignFamilyTask(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_JOB_OFFER_SYSTEM,
      ...reqData,
    };
    return this.postAuxEnd("/assigntask", postData);
  }

  // FUNCTION TO GET FAMILY SUGGEST LIST
  getFamilySuggestList() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      limit: 30,
      offset: 0,
      action: apiConst.WRENCHBOARD_JOB_OFFER_SYSTEM,
    };
    return this.postAuxEnd("/familysuggestlist", postData);
  }

  // FUNCTION TO CHANGE PROFILE PASSWORD
  profilePassChange(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_PROFILE_RESETPASS,
      ...reqData,
    };
    return this.postAuxEnd("/profilepasschange", postData);
  }

  // FUNCTION TO GET ACCOUNT SETTINGS
  getAccSettings() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_GETUSER_SETTINGS,
    };
    return this.postAuxEnd("/getaccsettings", postData);
  }

  // FUNCTION TO SET ACCOUNT SETTINGS
  setAccSettings(reqdata) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_USER_SETTINGS,
      ...reqdata,
    };
    return this.postAuxEnd("/setaccsettings", postData);
  }

  // FUNCTION TO DELETE PAY CARD
  payRemCard(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_USER_CARDREMV,
      ...reqData,
    };
    return this.postAuxEnd("/payremcard", postData);
  }

  getHomeRecent() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      limit: 30,
      offset: 0,
      action: apiConst.WRENCHBOARD_ACCOUNT_RECENTS,
    };
    return this.postAuxEnd("/recentactivities", postData);
  }
  // FUNCTION TO GET SINGLE BLOG ITEM
  getSingleBlogData(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      limit: 4,
      ...reqData,
    };
    return this.postAuxEnd("/blogdata", postData);
  }

  // FUNCTION TO CANCEL TASK OR SEND REMINDER BY FAMILY MEMBER
  suggestStatus(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_FAMILY_SGGESTSTATUS,
      ...reqData,
    };
    return this.postAuxEnd("/suggeststatus", postData);
  }

  // FUNCTION TO GET FAMILY WALLET AS A KID
  getFamilyWallet(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_FAMILY_WALLET,
      ...reqData,
    };
    return this.postAuxEnd("/familywallet", postData);
  }

  // FUNCTION TO GET FAMILY WALLET AS A PARENT
  getKidWallets(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_FAMILY_WALLET,
      ...reqData,
    };
    return this.postAuxEnd("/kidwallets", postData);
  }

  // FUNCTION TO START FAMILY TRANSFER
  familyTransferStart(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      ...reqData,
    };
    return this.postAuxEnd("/familytransferstart", postData);
  }

  // FUNCTION TO PERFORM FAMILY TRANSFER
  familyTransfer(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      ...reqData,
    };
    return this.postAuxEnd("/familytransfer", postData);
  }

  // FUNCTION GET JOB GROUP LIST
  jobGroupList(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_JOB_JOBGROUPS,
      ...reqData,
    };
    return this.postAuxEnd("/jobgrouplist", postData);
  }

  // FUNCTION TO ADD JOB GROUP
  jobGroupAdd(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      ...reqData,
    };
    return this.postAuxEnd("/jobgroupadd", postData);
  }

  // FUNCTION TO ADD JOB GROUP MEMBER
  groupMemberAdd(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      ...reqData,
    };
    return this.postAuxEnd("/groupmemberadd", postData);
  }

  // API FUNCTION TO GET FAMILY RELATIVE
  getFamilyRelativeList() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      offset: 1,
      limit: 20,
      action: apiConst.WRENCHBOARD_RELATIVE_LIST,
    };
    return this.postAuxEnd("/familyrellist", postData);
  }

  // API FUNCTION TO ADD/INVITE FAMILY RELATIVE
  inviteFamilyRelative(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_RELATIVE_INVITE,
      ...reqData,
    };
    return this.postAuxEnd("/familyrelinvite", postData);
  }

  // API FUNCTION TO FAMILY BANNERS
  getFamilyBannersList() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_ACCOUNT_FAMILY_BANNERS,
      limit: 20,
      offset: 1,
    };
    return this.postAuxEnd("/familybanners", postData);
  }

  // API FUNCTION TO FAMILY RESOURCES
  getFamilyResources() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_ACCOUNT_FAMILY_RESOURCES,
      limit: 20,
      offset: 1,
    };
    return this.postAuxEnd("/familyresources", postData);
  }

  // API FUNCTION TO GET FAMILY BLOG DATA
  getFamilyBlogData() {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      page: 0,
      offset: 0,
      limit: 100,
    };
    return this.postAuxEnd("/blogdata", postData);
  }

  // API FUNCTION FOR FAMILY WALLET REDEEM OPTIONS
  getFamilyWalletRedeemOptions() {
    var postData = {
      uuid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_JOB_ACTIVE,
      offset: 0,
      limit: 30,
    };
    return this.postAuxEnd("/familywallet/redeem/options", postData);
  }

  // API FUNCTION FOR PARENT TO CALL COMMON MEDIA
  getParentCommonMedia() {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_ACCOUNT_FAMILY_RESOURCES,
      offset: 1,
      limit: 20,
    };
    return this.postAuxEnd("/commonmedia", postData);
  }

  // API FUNCTION FOR PARENT TO ASSIGN MEDIA TASK
  parentAssignMediaTask(reqData) {
    var postData = {
      uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_JOB_OFFER_SYSTEM,
      ...reqData
    };
    return this.postAuxEnd("/assignmediatask", postData);
  }

  // API FUNCTION FOR LISTING JOB MANAGER FILES (TASK FILE UPLAOD)
  jobManagerFiles(reqData) {
    var postData = {
      member_uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.JOB_MANAGER_FILES,
      ...reqData
    };
    return this.postAuxEnd("/jobmanagerfiles", postData);
  }

  // API FUNCTION FOR GETTING LIST OF VERIFY COMPLETED TASK
  getVerifyCompletedTask(reqData) {
    var postData = {
      member_uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      action: apiConst.WRENCHBOARD_VERIFY_COMPLETED_TASK,
      ...reqData
    };
    return this.postAuxEnd("/verifycompleted", postData);
  }

  // API FUNCTION TO VERIFY PROMO LINK
  verifyPromo(reqData) {
    var postData = {
      action: apiConst.WRENCHBOARD_VERIFY_PROMO,
      ...reqData
    };
    return this.postAuxEnd("/promoverify", postData);
  }

  // API FUNCTION TO LOGIN USER THROUGH PROMO LINK
  loginPromo(reqData) {
    var postData = {
      action: apiConst.WRENCHBOARD_LOGIN_PROMO,
      ...reqData
    };
    return this.postAuxEnd("/loginpromo", postData);
  }

  // API FUNCTION TO GET LEARN MORE TOPICS
  getLearnmoreTopics() {
    var postData = {
      member_uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      // action: apiConst.WRENCHBOARD_LOGIN_PROMO,
    };
    return this.postAuxEnd("/learnmore", postData);
  }
  
  // API FUNCTION RECENTLY PAST DUE
  getRecentPastDueList() {
    var postData = {
      member_uid: localStorage.getItem("uid"),
      member_id: localStorage.getItem("member_id"),
      sessionid: localStorage.getItem("session_token"),
      // action: apiConst.WRENCHBOARD_LOGIN_PROMO,
    };
    return this.postAuxEnd("/recentpastdue", postData);
  }

  /*
    - 20:27:30.118 FLOG_MAX [757411]: 	REQ_STRING(username)
    - 20:27:30.118 FLOG_MAX [757411]: 	REQ_STRING(password)
    - 20:27:30.118 FLOG_MAX [757411]: 	REQ_STRING(sessionid)


    $endpoints = array(
        'apigate' => array('POST'),
        'generics' => array('POST'),
        'createuser' => array('POST'),
        'createmobileuser' => array('POST'),
        'completemobileuser' => array('POST'),
        'startresetpasword' => array('POST'),
        'userlogin' => array('POST'),
        'startjoblist' => array('POST'),
        'dashdata' => array('POST'),
        'getjobsdata' => array('POST'),
        'offerslist' => array('POST'),
        'activejoblist' => array('POST'),
        'loadprofile' => array('POST'),
        'account' => array('POST'),
        'message' => array('POST'),
        'pendingjob' => array('POST'),
        'paymenthx' => array('POST'),
        'getjob' => array('POST'),
        'mybanklist' => array('POST'),
        'sendmoney' => array('POST'),
        'sendinterest' => array('POST'),
        'sendmoneyfee' => array('POST'),
        'getpendingjobs' => array('POST'),
        'taskmessage' => array('POST'),
        'sendtaskmessage' => array('POST'),
        'getwallets' => array('POST'),
        'sitecontact' => array('POST'),
        'signupcountry' => array('POST'),
        'userscards' => array('POST'),
        'blogdata' =>  array('POST'),
        'blogitem' =>  array('POST'),
        'couponhx' =>  array('POST'),
        'couponpending' =>  array('POST'),
        'couponredeem' =>  array('POST'),
        'sendinterestmessage' =>  array('POST'),
        'replyinterestmessage' => array('POST')
    );

    */

  getUserReminders() {
    return this.getAuxEnd("/reminders", {});
  }
  //----------------------------------------  -----
  //----------------------------------------  -----
  // Unified call below
  //----------------------------------------  -----
  //----------------------------------------  -----
  getAuxEnd(uri, reqData) {
    const session_token = localStorage.getItem("session_token");
    let axiosConfig = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Basic ${session_token}`,
      },
    };
    const endPoint = process.env.REACT_APP_USERS_ENDPOINT + uri;
    return Axios.get(endPoint, {
      params: {
        reqData,
      },
    })
      .then((response) => {
        console.log("~~~~~~~ Toks2 GET ~~~~~~~~");
        return response;
      })
      .catch((error) => {
        if (error.response) {
          //response status is an error code
          console.log(error.response.status);
        } else if (error.request) {
          //response not received though the request was sent
          console.log(error.request);
        } else {
          //an error occurred when setting up the request
          console.log(error.message);
        }
      });
  }

  postAuxEnd(uri, reqData, uploadPost=false) {
    const endPoint = uploadPost ? process.env.REACT_APP_MEDIA_LINK + uri : process.env.REACT_APP_USERS_ENDPOINT + uri;
    const session_token = localStorage.getItem("session_token");
    // session_token = session_token !=null ?session_token : '';
    //   'Authorization': `Basic ${(session_token !=null) ?session_token : ''}`,
    let axiosConfig = {
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Expose-Headers": "Access-Control-Allow-Origin",
        "Access-Control-Allow-Headers":
            "Origin, X-API-KEY, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method, Access-Control-Allow-Headers, Authorization, observe, enctype, Content-Length, X-Csrf-Token",
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    //  Axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    //     Axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'; //,axiosConfig
    //     Axios.defaults.withCredentials = true;
    //debugger;
    if(localStorage && localStorage.getItem('myloc')){
      reqData.loc = localStorage.getItem('myloc')
    }
    return Axios.post(endPoint, reqData)
        .then((response) => {
          console.log(response);
          console.log("~~~~~~~ WrenchBoard::POST ~~~~~~~~");
          if (response.data.internal_return == "-9999") {
            localStorage.clear();
            window.location.href = `/login?sessionExpired=true`;
          }
          return response;
        })
        .catch((error) => {
          if (error.response) {
            //response status is an error code
            console.log(
                "ERROR-------------------------------------------------------"
            );
            console.log(error.response.status);
            console.log(
                "ERROR-------------------------------------------------------"
            );
          } else if (error.request) {
            //response not received though the request was sent
            console.log(
                "ERROR2-------------------------------------------------------"
            );
            console.log(error?.request);
            console.log(
                "ERROR2-------------------------------------------------------"
            );
          } else {
            //an error occurred when setting up the request
            console.log(
                "ERROR3-------------------------------------------------------"
            );
            console.log(error);
            console.log(
                "ERROR3-------------------------------------------------------"
            );
          }
        });
  }

  postAuxEnd_BROKE(uri, reqData) {
    const endPoint = process.env.REACT_APP_USERS_ENDPOINT + uri;
    const endPointKey = process.env.REACT_APP_ENDPOINT_KEY;
    const session_token = localStorage.getItem("session_token");
    // session_token = session_token !=null ?session_token : '';
    //   'Authorization': `Basic ${(session_token !=null) ?session_token : ''}`,
    let axiosConfig = {
      headers: {
              Accept: "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Expose-Headers": "Access-Control-Allow-Origin",
              "Access-Control-Allow-Headers":
              "Origin, X-API-KEY, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method, Access-Control-Allow-Headers, Authorization, observe, enctype, Content-Length, X-Csrf-Token",
              "Content-Type": "application/json;charset=UTF-8"
              },
    };
    //,
    //               "x-api-key":`${endPointKey}`
    //  Axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    //     Axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'; //,axiosConfig
    //     Axios.defaults.withCredentials = true;
    //debugger;
    // Set default header. e.g, X-API-KEY
   // Axios.defaults.headers['X-API-KEY'] = endPointKey;
    return Axios.post(endPoint, reqData)
      .then((response) => {
        console.log(response);
        // res = response;
        console.log("~~~~~~~ Toks2 POST ~~~~~~~~");
        if (response.data.internal_return == "-9999") {
          localStorage.clear();
          window.location.href = `/login?sessionExpired=true`;
        }
        return response;
      })
      .catch((error) => {
        if (error.response) {
          //response status is an error code
          console.log(
            "ERROR-------------------------------------------------------"
          );
          console.log(error.response.status);
          console.log(
            "ERROR-------------------------------------------------------"
          );
        } else if (error.request) {
          //response not received though the request was sent
          console.log(
            "ERROR2-------------------------------------------------------"
          );
          console.log(error?.request);
          console.log(
            "ERROR2-------------------------------------------------------"
          );
        } else {
          //an error occurred when setting up the request
          console.log(
            "ERROR3-------------------------------------------------------"
          );
          console.log(error);
          console.log(
            "ERROR3-------------------------------------------------------"
          );
        }
      });
  }
}

export default usersService;
