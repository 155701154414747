import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import linkedInLogo from "../../../assets/images/Linkedin.png";
import appleLogo from "../../../assets/images/apple-black.svg";
import facebookLogo from "../../../assets/images/facebook.svg";
import googleLogo from "../../../assets/images/google-logo.svg";
import WrenchBoard from "../../../assets/images/wrenchboard-logo-text.png";
import usersService from "../../../services/UsersService";
import InputCom from "../../Helpers/Inputs/InputCom";
import AuthLayout from "../AuthLayout2";
// import { GoogleOAuthProvider } from '@react-oauth/google';
import { useGoogleLogin } from "@react-oauth/google";

import { useDispatch } from "react-redux";
import { updateUserDetails } from "../../../store/UserDetails";

import ReCAPTCHA from "react-google-recaptcha";

import GoogleDownload from '../../../assets/images/download/andriod.jpg'
import IOSDownload from '../../../assets/images/download/apple.jpg'

export default function Login() {
  // eslint-disable-next-line no-restricted-globals
  const queryParams = new URLSearchParams(location?.search);
  // const sessionExpired = queryParams.get("sessionExpired");

  const dispatch = useDispatch();
  const { state } = useLocation();

  const [sessionExpired, setSessionExpired] = useState(
    queryParams.get("sessionExpired")
  );

  const [validCaptcha, setValidCaptcha] = useState({ show: false, valid: "" }); // FOR CAPTCHA

  let [loginType, setLoginType] = useState("");

  const [loginLoading, setLoginLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  //login error state
  const [loginError, setLoginError] = useState(false);
  // for the catch error
  const [msgError, setMsgError] = useState("");

  // To Show and Hide Password
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  //FUNCTION TO DETERMINE/CHANGE LOGIN COMPONENT
  const handleLoginType = ({ target: { name } }) => {
    setLoginType(name);
    let currentDate = new Date();
    let expirationDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
    // Convert the expiration date to the appropriate format
    let expirationDateString = expirationDate.toUTCString();
    document.cookie = `loginType=${name}; expires=${expirationDateString}; path=/;`;
  };

  // email
  const [email, setMail] = useState("");
  const handleEmail = (e) => {
    setMail(e.target.value);
  };
  // password
  const [password, setPassword] = useState("");
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const navigate = useNavigate();
  const userApi = new usersService();

  // FUNCTION TO HANDLE USER LOGIN
  const doLogin = ({ target: { name } }) => {
    setMsgError("");
    setLoginError(false);
    setLoginLoading(true);
    let postData; // Post Data for API
    if (!email || !password) {
      setLoginLoading(false);
      setMsgError("Please fill all the fields");
      setTimeout(() => {
        setMsgError("");
      }, Number(process.env.REACT_APP_LOGIN_ERROR_TIMEOUT));
      return;
    }

    if (name == "full") {
      //checks if email is a valid email address
      let regEx = /^[^0-9][a-zA-Z0-9._%+-]+@[a-zA-Z]+(\.[a-zA-Z]+)+$/;
      if (regEx.test(email) == false) {
        setLoginLoading(false);
        setMsgError("Invalid Email");
        return setTimeout(() => {
          setMsgError("");
        }, 3000);
      }
      // Post Data Info for normal Login
      postData = {
        username: email,
        password: password,
        sessionid: "STARTING",
        login_mode: 1100,
      };
    } else if (name == "family") {
      // Post Data Info for family Login
      postData = {
        username: email,
        pin: password,
        sessionid: "20067A92714",
        login_mode: 1105,
      };
    } else {
      setLoginLoading(false);
      setMsgError("Invalid Login Type. Consider refreshing the page");
      setTimeout(() => {
        setMsgError("");
      }, Number(process.env.REACT_APP_LOGIN_ERROR_TIMEOUT));
      return;
    }
    if (name == "full" && !validCaptcha.valid && validCaptcha.show) {
      // RUNS AND DISPLAYS CAPTCHA, IF ERROR OCCURED DURING LOGIN FOR FULL LOGIN ALONE
      setMsgError("Please Verify Captcha");
      setLoginLoading(false);
      setTimeout(() => {
        setMsgError("");
      }, Number(process.env.REACT_APP_LOGIN_ERROR_TIMEOUT));
      return;
    }
    userApi
      .logInUser(postData)
      .then((res) => {
        if (
          res.status != 200 ||
          res.data.internal_return < 0 ||
          !res.data.member_id ||
          !res.data.uid ||
          !res.data.session
        ) {
          // setMsgError("Wrong, email/password");
          setLoginError(true);
          setLoginLoading(false);
          setValidCaptcha((prev) => ({ ...prev, show: true })); // DISPLAYS CAPTCHA IF ERROR
          return;
        }
        localStorage.setItem("member_id", `${res.data.member_id}`);
        localStorage.setItem("uid", `${res.data.uid}`);
        localStorage.setItem("session_token", `${res.data.session}`);
        localStorage.setItem("wallet_available_status", `${res.data.wallet_available_status}`);
        if (res.data?.account_type == "FAMILY") {
          sessionStorage.setItem("family_uid", res.data?.family_uid);
          sessionStorage.setItem("parent_uid", res.data?.parent_uid);
        }
        // localStorage.setItem("session", `${res.data.session}`);
        dispatch(updateUserDetails({ ...res.data }));
        setTimeout(() => {
          navigate("/", { replace: true });
          setLoginLoading(false);
        }, 2000);
      })
      .catch((error) => {
        setMsgError("Unable to login, try again");
        setLoginLoading(false);
        setValidCaptcha((prev) => ({ ...prev, show: true })); // DISPLAYS CAPTCHA IF ERROR
      })
      .finally(() => {
        setTimeout(() => {
          setLoginError(false);
          setMsgError("");
          setLoginLoading(false);
        }, Number(process.env.REACT_APP_LOGIN_ERROR_TIMEOUT));
      });
  };

  function captchaChecker(value) {
    // FUNCTION TO VALIDATE CAPTCHA
    if (value) {
      setValidCaptcha({ show: true, valid: value });
    } else {
      setValidCaptcha({ show: true, valid: "" });
    }
  }

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    ux_mode: "redirect",
    redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
    onSuccess: async (codeResponse) => {
      console.log("GOOGLE LOGIN GOOD --- ", codeResponse);
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  // In order to update the selected login type whenever the component renders
  // useEffect(() => {
  // Clear the loginType cookie if the user switches to loginfull
  // document.cookie ="loginType=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  // }, []);

  useLayoutEffect(() => {
    // checks the cookie in order to set the login type before components mounts
    // if(document.cookie.includes("loginType=family")){
    //   setLoginType('family')
    // }else if(document.cookie.includes("loginType=full")){
    //   setLoginType('full')
    // }else{
    //   setLoginType('full')
    // }
    function readCookie(cname) {
      // checks the cookie in order to set the login type before components mounts
      let name = cname + "=";
      let decoded_cookie = decodeURIComponent(document.cookie);
      let carr = decoded_cookie.split(";");
      for (let i = 0; i < carr.length; i++) {
        let c = carr[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "full";
    }
    let loginValue = readCookie("loginType");
    setLoginType(loginValue);

    if (state?.error) {
      //check if the login path has an error state indicating any social handle login with error
      setMsgError("Unexpected Error, Please try again soon.");
      setTimeout(() => {
        setMsgError("");
        navigate("/login", { replace: true });
      }, 4000);
    }
  }, []);

  useEffect(() => {
    setMail("");
    setPassword("");
  }, [loginType]);

  // EFFECT TO CLEAR SESSION EXPIRY IF IT EXISTS AFTER SOME SECONDS
  useEffect(() => {
    let timer;
    if (sessionExpired == "true") {
      timer = setTimeout(() => {
        setSessionExpired(false);
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <AuthLayout slogan="Welcome to WrenchBoard">
        <div className="w-full">
          <div className="mb-5">
            <Link to="#">
              <img
                src={WrenchBoard}
                alt="wrenchboard"
                className="h-10 mx-auto"
              />
            </Link>
          </div>
          {/* <div className="content-wrapper login shadow-md w-10/12 mx-auto flex justify-center items-center xl:bg-white dark:bg-dark-white 2xl:w-[828px]  rounded-[0.475rem] sm:p-7 p-5"> */}
          <div className="flex place-content-center">
            <div className="w-10/12">
              {/* HIDES THIS IF USER SESSION HAS EXPIRED */}
              {sessionExpired != "true" && (
                <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                  {/* <h1 className="text-[#181c32] font-semibold dark:text-white mb-3 leading-[27.3px] text-[22.75px]">
                  Sign In to WrenchBoard
                </h1> */}
                  <span className="text-gray-400 font-medium text-[16.25px] leading-[24.375px]">
                    New Here?{" "}
                    <Link
                      to="/signup"
                      className="font-semibold text-[#4687ba] hover:text-[#009ef7] transition"
                    >
                      Create an Account
                    </Link>
                  </span>
                </div>
              )}

              {/* SHOWS THIS IF USER SESSION HAS EXPIRED */}
              {sessionExpired == "true" && (
                <div className="w-full p-1 mb-7">
                  <p className="text-red-500 text-base text-center">
                    Your session expired and will need to login again
                  </p>
                </div>
              )}

              {/* switch login component */}
              <div className="ml-7 flex justify-start items-center gap-3">
                <button
                  name="full"
                  className={`login-type-btn px-4 py-1 rounded-t-2xl ${
                    loginType == "full"
                      ? "bg-[#4687ba] border-[2px] border-[#4687ba] text-white"
                      : "bg-white text-[#000] border-t-[2px]"
                  }`}
                  onClick={handleLoginType}
                >
                  Sign in
                </button>
                <button
                  name="family"
                  className={`login-type-btn px-4 py-1 rounded-t-2xl ${
                    loginType == "family"
                      ? "bg-[#4687ba] border-[2px] border-[#4687ba] text-white"
                      : "bg-white text-[#000] border-t-[2px]"
                  }`}
                  onClick={handleLoginType}
                >
                  Family Account
                </button>
              </div>

              {/* END  of switch login component */}

              {/* for login component */}
              {
                loginType == "full" ? (
                  //user login component
                  <div className="p-6 input-area login-area border-2 border-[#4687ba] rounded-2xl">
                    <div className="input-item mb-5">
                      <InputCom
                        labelClass="tracking-wider"
                        fieldClass="sm:px-6 px-2"
                        value={email}
                        inputHandler={handleEmail}
                        placeholder="Your Email"
                        label="Email"
                        name="email"
                        type="email"
                        iconName="message"
                      />
                    </div>

                    <div className="input-item mb-5">
                      <InputCom
                        labelClass="tracking-wider"
                        fieldClass="sm:px-6 px-2 tracking-[0.25em] text-2xl"
                        value={password}
                        inputHandler={handlePassword}
                        placeholder="● ● ● ● ● ●"
                        label="Password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        onClick={togglePasswordVisibility}
                        passIcon={showPassword ? "password" : "password"}
                        forgotPassword
                      />
                    </div>

                    {/* hCaptha clone for the time being */}
                    {validCaptcha.show && (
                      <div className="mb-5 flex justify-center w-full">
                        <ReCAPTCHA
                          sitekey={
                            process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY
                          }
                          onChange={captchaChecker}
                        />
                      </div>
                    )}

                    {loginError && (
                      <div className="relative p-4 text-[#912741] bg-[#fcd9e2] border-[#fbc6d3] mb-4 rounded-[0.475rem] text-md font-thin leading-[19.5px] text-[13px]">
                        Invalid username or password- Please{" "}
                        <Link to="/#" className="text-[#009ef7]">
                          reset your password
                        </Link>{" "}
                        or{" "}
                        <Link to="/signup" className="text-[#009ef7]">
                          create a new account
                        </Link>
                      </div>
                    )}
                    {msgError && (
                      <div className="relative p-4 text-[#912741] bg-[#fcd9e2] border-[#fbc6d3] mb-4 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]">
                        {msgError}
                      </div>
                    )}
                    <div className="signin-area mb-3.5">
                      <div className="flex justify-center">
                        <button
                          name="full"
                          onClick={doLogin}
                          type="button"
                          disabled={loginLoading}
                          className={`btn-login  rounded-full mb-6 text-xl text-white flex justify-center bg-[#4687ba] hover:bg-[#009ef7] transition-all duration-300 items-center text-[15px]`}
                        >
                          {loginLoading ? (
                            <div className="signup btn-loader"></div>
                          ) : (
                            <>Continue</>
                          )}
                        </button>
                      </div>
                      <div className="sm:grid grid-cols-2 gap-1">
                        <div className="w-full">
                          <BrandBtn
                            link="#"
                            imgSrc={googleLogo}
                            brand="Google"
                            onClick={googleLogin}
                          />
                        </div>
                        <div
                          className={`w-full ${
                            process.env.REACT_APP_APPLE_SOCIAL_LOGIN !== 0 &&
                            "hidden"
                          }`}
                        >
                          <BrandBtn
                            // link={`https://appleid.apple.com/auth/authorize?response_type=code&response_mode=form_post&client_id=${process.env.REACT_APP_APPLE_CLIENT_ID}&redirect_uri=https%3A%2F%2Fwork.wrenchboard.com%2Flogin%2Fauth%2Fapple&state=4b2c4456b7&scope=name+email`}
                            link={`https://appleid.apple.com/auth/authorize?response_type=code&response_mode=form_post&client_id=${process.env.REACT_APP_APPLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_APPLE_REDIRECT_URL}&state=4b2c4456b7&scope=name+email`}
                            imgSrc={appleLogo}
                            brand="Apple"
                            isAnchor={true}
                            // style={{visibility: 'hidden'}}
                          />
                        </div>
                        <div className="w-full">
                          <BrandBtn
                            link={`https://www.facebook.com/v14.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FACEBOOK_REDIRECT_URL}&scope=${process.env.REACT_APP_FACEBOOK_CLIENT_SCOPE}`}
                            imgSrc={facebookLogo}
                            brand="Facebook"
                            isAnchor={true}
                          />
                        </div>
                        <div
                          className={`w-full ${
                            process.env.REACT_APP_LINKEDIN_SOCIAL_LOGIN !== 0 &&
                            "hidden"
                          }`}
                        >
                          <BrandBtn
                            // link="https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=YOUR_CLIENT_ID&redirect_uri=YOUR_REDIRECT_URI&scope=comma-separated-list-of-scopes&state=YOUR_STATE_VALUE"
                            imgSrc={linkedInLogo}
                            brand="LinkedIn"
                            isAnchor={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  // END of user login compoenent
                  // family login compoenent
                  <div className="p-6 input-area login-area border-2 border-[#4687ba] rounded-2xl">
                    <div className="input-item mb-5">
                      <InputCom
                        labelClass="tracking-wider"
                        fieldClass="px-6"
                        value={email}
                        inputHandler={handleEmail}
                        placeholder="Account ID"
                        label="Username"
                        name="email"
                        type="email"
                        iconName="family-id"
                      />
                    </div>

                    <div className="input-item mb-5">
                      <InputCom
                        labelClass="tracking-wider"
                        fieldClass="px-6"
                        value={password}
                        inputHandler={handlePassword}
                        placeholder="● ● ● ● ● ●"
                        label="Pin"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        onClick={togglePasswordVisibility}
                        passIcon={showPassword ? "family-pin" : "family-pin"}
                      />
                    </div>
                    {loginError && (
                      <div className="relative p-4 text-[#912741] bg-[#fcd9e2] border-[#fbc6d3] mb-4 rounded-[0.475rem] text-md font-thin leading-[19.5px] text-[13px]">
                        Invalid username or password{" "}
                        {/* <Link to="/#" className="text-[#009ef7]">
                        reset your password
                      </Link>{" "}
                      or{" "}
                      <Link to="/signup" className="text-[#009ef7]">
                        create a new account
                      </Link> */}
                      </div>
                    )}
                    {msgError && (
                      <div className="relative p-4 text-[#912741] bg-[#fcd9e2] border-[#fbc6d3] mb-4 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]">
                        {msgError}
                      </div>
                    )}
                    <div className="signin-area mb-1.5">
                      <div className="flex justify-center">
                        <button
                          name="family"
                          onClick={doLogin}
                          disabled={loginLoading}
                          type="button"
                          className={`btn-login  rounded-full text-xl text-white flex justify-center bg-[#4687ba] hover:bg-[#009ef7] transition-all duration-300 items-center text-[15px]`}
                        >
                          {loginLoading ? (
                            <div className="signup btn-loader"></div>
                          ) : (
                            <>Continue</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                )
                // END of family login compoenent
              }
              {/* END of login component */}

              {/* APP DOWNLOAD STORE */}
              <div className="w-full mt-4">
                <div className="w-full flex justify-center items-center gap-4">
                  <div className="w-32 lg:w-48">
                    <a
                      // className="px-1 py-1 lg:py-2 flex justify-center items-center gap-1 w-full rounded-md bg-black text-white hover:text-slate-500 hover:shadow-lg transition-all duration-300"
                      target="_blank"
                      rel="noreferrer"
                      href={process.env.REACT_APP_APPLE_APP}
                    >
                      {/* <i className="fa-brands fa-apple text-3xl"></i>
                      <div className="flex flex-col">
                        <span className="text-[11px]">Available on the</span>
                        <span className="text-[12px] lg:text-base">
                          App Store
                        </span>
                      </div> */}
                      <img src={IOSDownload} className='w-full h-auto' alt='IOS Download' />
                    </a>
                  </div>
                  <div className="w-32 lg:w-48">
                    <a
                      // className="px-1 py-1 lg:py-2 flex justify-center items-center gap-1 w-full rounded-md bg-black text-white hover:text-slate-500 hover:shadow-lg transition-all duration-300"
                      target="_blank"
                      rel="noreferrer"
                      href={process.env.REACT_APP_ANDROID_APP}
                    >
                      {/* <i className="fa-brands fa-google-play text-2xl"></i>
                      <div className="flex flex-col">
                        <span className="text-[11px]">Available on the</span>
                        <span className="text-[12px] lg:text-base">
                          Google Play
                        </span>
                      </div> */}
                      <img src={GoogleDownload} className='w-full h-auto' alt='IOS Download' />
                    </a>
                  </div>
                </div>
              </div>

              {loginType == "full" && (
                <>
                  <div className="pt-5 text-[#181c32] text-center font-semibold text-[13.975px] leading-[20.9625px]">
                    This site is protected by a Captcha. <br />Our Privacy Policy and
                    Terms of Service apply.
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}

const BrandBtn = ({
  link,
  imgSrc,
  brand,
  onClick,
  isAnchor = false,
  style = { visibility: "visible" },
}) => {
  // const doGoogle = async () => {
  //   alert("start google");
  // };

  //  onSuccess: (codeResponse) => setUser(codeResponse),

  // const doGoogle = useGoogleLogin({
  //   onSuccess: (codeResponse) =>  console.log('Login onSuccess:', codeResponse),
  //   onError: (error) => console.log('Login Failed:', error)
  // });

  // const doApple = async () => {
  //   alert("start apple");
  // };

  // const doFacebook = async () => {
  //   alert("start facebook");
  // };
  return (
    <div className="w-full flex justify-center bottomMargin" style={style}>
      {isAnchor ? (
        <a
          href={link}
          className="w-full border border-light-purple rounded-[0.475rem] h-[48px] flex justify-center bg-[#FAFAFA] hover:bg-[#eff2f5] hover:text-[#7e8299] transition duration-300 items-center font-medium cursor-pointer"
        >
          <img className="mr-3 h-6" src={imgSrc} alt="logo-icon(s)" />
          <span className="text-lg text-thin-light-gray font-normal text-[15px]">
            Continue with {brand}
          </span>
        </a>
      ) : (
        <button
          onClick={onClick}
          // href="#dd"
          className="w-full border border-light-purple  rounded-[0.475rem] h-[48px] flex justify-center bg-[#FAFAFA] hover:bg-[#eff2f5] hover:text-[#7e8299] transition duration-300  items-center font-medium cursor-pointer"
        >
          <img className="mr-3 h-6" src={imgSrc} alt="logo-icon(s)" />
          <span className="text-lg text-thin-light-gray font-normal text-[15px]">
            Continue with {brand}
          </span>
        </button>
      )}
    </div>
  );
};
